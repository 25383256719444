@import 'src/assets/scss/variables';

.expandedOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: hsl(209,60%,70%, 0.7);
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.collapsedOverlay {
  display: none;
}

@media (min-width: $xl) {
  .expandedOverlay {
    margin-left: $sidebar-width !important;
  }
}
