$modal-header-border-color: transparent;
$modal-sm: 80vw;
$modal-md: 650px;
$modal-inner-padding-x: 3rem;

.modal-dialog {
  padding-top: 50px;
  .modal-header,
  .modal-body,
  .modal-footer {
    padding-left: $modal-inner-padding-x;
    padding-right: $modal-inner-padding-x;
  }
  .modal-footer {
    padding-bottom: 2rem;
    justify-content: center;
  }
}
.modal-body {    
  max-height: calc(100vh - 380px);
  overflow-y: auto;
}
.modal-header .modal-title {
  font-size: 26px;
  color: $black;
}
.close-modal {
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3Csvg width='44' height='44' viewBox='0 0 44 44' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M22 44C9.84974 44 0 34.1503 0 22C0 9.84974 9.84974 0 22 0C34.1503 0 44 9.84974 44 22C44 34.1503 34.1503 44 22 44ZM22 40C31.9411 40 40 31.9411 40 22C40 12.0589 31.9411 4 22 4C12.0589 4 4 12.0589 4 22C4 31.9411 12.0589 40 22 40ZM15.4144 31.4144L22.0002 24.8286L28.5859 31.4144L31.4144 28.5859L24.8286 22.0002L31.4144 15.4144L28.5859 12.5859L22.0002 19.1717L15.4144 12.5859L12.5859 15.4144L19.1717 22.0002L12.5859 28.5859L15.4144 31.4144Z' fill='white'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center;
  width: 44px;
  height: 44px;
  cursor: pointer;
  position: absolute;
  right: -45px;
  top: -45px;
}

.modal-content {
  .btn {
    text-transform: uppercase;
    letter-spacing: 0.1em;
    min-width: 120px;
  }
}
.popover.popover-large {
  width: 80vw;
  max-width: unset;
}

@media (max-width: 767px) {
  .close-modal {
    display: none;
  }
}
