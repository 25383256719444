@import '/src/assets/scss/_variables.scss';

.containerCustom{
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1)
}

.navbar-base {
  min-height: $header-height;
  background-color: $white;
}

.navbar-nav {
  .nav-link {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: $primary-700;
    + .nav-item {
      margin-left: 21px;
    }
    &:hover {
      color: $primary;
    }
  }
}
.navbar-base .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-brand {
  cursor: pointer;
}
.user {
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 15px;
  color: $gray-500;
  svg {
    fill: $primary-200;
  }
  .avatar {
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }
  .logout {
    width: 24px;
    height: 24px;
  }
  .mobileMenuControls {
    display: flex;
    gap: 31px;
    .checkMenu {
      position: relative;
    }
    .unchecked-badge {
      position: absolute;
      top: 4px;
      right: -17px;
    }
    .burgerControl {
      width: 24px;
      height: 24px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.username {
  max-width: 130px;
  word-wrap: break-word;
}

.sidebar-btn {
  margin-right: 25px;
  margin-left: 15px;
  width: 25px;
  height: 25px;
  color: $primary-700;
  cursor: pointer;
}

.breadcrumbs {
  color: $primary;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 2;
  background-color: $gray-200;
  min-height: $breadcrumb-min-height;
  a {
    color: $primary !important;
    font-size: 16px !important;
  }
  svg {
    width: 13px;
    color: $primary !important;
    font-size: 16px !important;
  }
}

.mobileOverlay {
  position: fixed;
  top: $header-height;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: hsl(209,60%,70%, 0.7);
  z-index: 10000;
}

.mobileNavigationMenuContainer {
  background-color: $white;
}

.mobileLogout {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  background-color: $gray-200;
  gap: 10px;
  .logoutIcon {
    width: 15px;
    height: 15px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .text {
    color: $gray-500;
    font-size: $mobile-p;
  }
}


@media (min-width: $xl) {
  .navbar-base  {
    margin-left: $sidebar-width !important;
  }
  .sidebar-margin  {
    margin-left: $sidebar-width !important;
  }
  .containerCustom {
    .container {
      padding-left: 13px;
      padding-right: 13px;
    }
  }
}

@media (max-width: $sm) {
  .sidebar-btn  {
    margin-left: 0;
  }
}

@media (min-width: $lg) {
  .sidebar-btn  {
    margin-left: 13px;
  }
}
