@import './assets/scss/variables';

* {
  padding: 0;
  margin: 0;
}

html,
body {
  height: 100%;    
}

#root {
  display: flex;
  min-height: 100vh;
  --fc-border-color: #eef2f7;
  --fc-today-bg-color: #fff;
  --fc-event-selected-overlay-color: rgba(0,0,0,0);
}

.custom-disabled-textarea-red {
  color: red !important;
}
.custom-disabled-textarea-green {
  color: green !important;
}


.form-control:disabled {
  color: black;
}

.App {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 0 !important;
}

.fetching-spinner {
  z-index: 200;
  position: fixed;
  right: 10px;
  bottom: 10px;
}

[data-rsbs-overlay] {
  border-top-left-radius: 16px !important;
  border-top-right-radius: 16px !important;
}

/* Basic editor styles */
.tiptap {
  :first-child {
    margin-top: 0;
  }

  img {
    display: block;
    height: auto;
    margin: 1.5rem 0;
    max-width: 100%;
  }

  /* List styles */
  ul,
  ol {
    padding: 0 1rem;
    margin: 1.25rem 1rem 1.25rem 0.4rem;

    li p {
      margin-top: 0.25em;
      margin-bottom: 0.25em;
    }
  }

  /* Heading styles */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
    margin-top: 2.5rem;
  }

  h1,
  h2 {
    margin-top: 3.5rem;
    margin-bottom: 1.5rem;
  }

  h1 {
    font-size: 1.4rem;
  }

  h2 {
    font-size: 1.2rem;
  }

  h3 {
    font-size: 1.1rem;
  }

  h4,
  h5,
  h6 {
    font-size: 1rem;
  }

  /* Code and preformatted text styles */
  code {
    background-color: var(--purple-light);
    border-radius: 0.4rem;
    color: var(--black);
    font-size: 0.85rem;
    padding: 0.25em 0.3em;
  }

  pre {
    background: var(--black);
    border-radius: 0.5rem;
    color: var(--white);
    font-family: 'JetBrainsMono', monospace;
    margin: 1.5rem 0;
    padding: 0.75rem 1rem;

    code {
      background: none;
      color: inherit;
      font-size: 0.8rem;
      padding: 0;
    }
  }

  blockquote {
    border-left: 3px solid var(--gray-3);
    margin: 1.5rem 0;
    padding-left: 1rem;
  }

  hr {
    border: none;
    border-top: 1px solid var(--gray-2);
    margin: 2rem 0;
  }

  table {
    border-collapse: collapse !important;
    color: black !important;
    margin: 0;
    overflow: hidden !important;
    table-layout: fixed !important;
    width: 100% !important;

    td,
    th {
      color: black !important;
      border: 1px solid rgb(230, 229, 229) !important;
      box-sizing: border-box !important;
      min-width: 1em !important;
      padding: 6px 8px !important;
      position: relative !important;
      vertical-align: top !important;    

      > * {
        margin-bottom: 0;
      }
    }

    th {
      background-color: white !important;      
      text-align: left !important;
    }

    .selectedCell:after {      
      background: #eef2f7 !important;         
      content: "" !important;     
      left: 0 !important; 
      right: 0 !important; 
      top: 0 !important; 
      bottom: 0 !important;
      pointer-events: none;
      position: absolute !important;
      z-index: 2 !important;
      opacity: 40% !important;     
           
      
    }

    .column-resize-handle {
      background-color: #eef2f7;
      bottom: -2px;
      pointer-events: none;
      position: absolute;
      right: -2px;
      top: 0;
      width: 4px;
    }
  }

  .tableWrapper {
    margin: 1.5rem 0;
    overflow-x: auto;
  }

  &.resize-cursor {
    cursor: ew-resize;
    cursor: col-resize;
  }

  // Mathematics extension styles
  .Tiptap-mathematics-editor {
    background: #202020;
    color: #fff;
    font-family: monospace;
    padding: 0.2rem 0.5rem;
  }

  .Tiptap-mathematics-render {
    padding: 0 0.25rem;

    &--editable {
      cursor: pointer;
      transition: background 0.2s;

      &:hover {
        background: #eee;
      }
    }
  }

  .Tiptap-mathematics-editor,
  .Tiptap-mathematics-render {
    border-radius: 0.25rem;
    display: inline-block;
  }

  //Iframes video
  .tiptap-iframe-wrapper {
    height: 300px;
    iframe {
      width: 100%;
      height: 100%;
    }
  }
}

@media (min-width: $xs) {
  .tiptap-iframe-wrapper  {
    height: 250px !important;
  }
}

@media (min-width: $sm) {
  .tiptap-iframe-wrapper  {
    height: 300px !important;
  }
}

@media (min-width: $md) {
  .tiptap-iframe-wrapper  {
    height: 400px !important;
  }
}

@media (min-width: $lg) {
  .tiptap-iframe-wrapper  {
    height: 405px !important;
  }
}

@media (min-width: $xl) {
  .tiptap-iframe-wrapper  {
    height: 550px !important;
  }
}

