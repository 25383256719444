@import 'src/assets/scss/variables';

.loginForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  // For VK button login
  //padding: 40px 40px 90px 40px;

  padding: 40px;
  margin: auto;

  background-color: $white;
  border-radius: $border-radius-lg;
  box-shadow: $box-shadow;

  position: relative;

  .Error {
    background-color: lightcoral;
    padding: 10px;
    font-weight: bold;
    border-radius: 5px;
  }
}

.button-vk {
  position: absolute;
  height: 50px;
  text-align: center;
  border-bottom-left-radius: $border-radius-lg;
  border-bottom-right-radius: $border-radius-lg;
  background-color: rgba($primary-700, 0.8);
  font-size: 18px;
  cursor: pointer;
  color: $white;
  bottom: 0;
  right: 0;
  left: 0;

  div {
    margin-top: 10px;
  }
}
