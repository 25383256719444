// Colors

$primary-700: #2a5a97;
$primary-900: #2c456b;
$primary: #389af4;
$primary-200: #acd6f1;
$primary-100: #deeff9;
$new-blue: #CAE6FF;
$gray-scale: #CDCDCD;
$success-light: #EDFFF4;
$danger-light: #FFF6F5;
$warning-light: #FFF9F0;
$success-medium: #7FE3A7;
$danger-medium: #FFA2A0;
$warning-medium: #FCCB7F;

$danger: #ed4340;
$secondary: #94cebe;

$black: #000000;
$white: #ffffff;

$gray-200: #eef2f7;
$gray-500: #a8a8a8;
$gray-900: #333333;

$theme-colors: (
  'white': $white,
  'black': $black,
  'primary': $primary,
  'primary-light': $primary-200,
  'primary-dark': $primary-700,
  'secondary': $secondary,
  'info': $primary-200,
  'danger': $danger,
  'gray-light': $gray-200,
);
$course-colors: (
  'socialStudies': #94cebe,
  'history': #cfb2d6,
  'math': #9db9e2,
  'russianLanguage': #fccb7f,
  'literature': #f2e282,
  'socialStudy': #94cebe,
  'englishLanguage': #f5a889,
  'chemistry': #fad3d7,
  'physics': #f4accd,
  'computerScience': #83d0f5,
  'biology': #c5d97d,
  'geography': #80c28b,
);

$body-bg: $gray-200;
$body-color: $black;
$breadcrumb-divider-color: inherit;

$box-shadow-sm: 0 0.125rem 0.25rem rgba($black, 0.075) !default;
$box-shadow: 0px 0px 30px rgba($black, 0.1) !default;
$box-shadow-lg: 0 1rem 3rem rgba($black, 0.175) !default;

$enable-shadows: false;

$font-family-sans-serif: 'Exo 2', sans-serif, -apple-system, 'Segoe UI',
  'Roboto', 'Ubuntu', 'Helvetica Neue';
$font-family-base: $font-family-sans-serif;

$h1-font-size: 52px;
$h2-font-size: 28px;
$h3-font-size: 24px;
$h4-font-size: 20px;
$heading-line-height: 1.16em;

$headings-font-weight: 700;
$h2-font-weight: 600;
$p-font-weight: 400;
$description-font-weight: 500;

$border-radius: 4px !default;
$border-radius-lg: 8px !default;
$border-radius-sm: 3px !default;

$header-height: 65px;
$breadcrumb-min-height: 35px;

// Forms
$input-focus-box-shadow: none !important;
$input-border-color: transparent;
$input-placeholder-color: $primary-200;
$input-border-radius: $border-radius-lg;
$input-focus-border-color: $primary-100;
$input-group-addon-color: $white;
$input-group-addon-bg: $primary;
$input-disabled-bg: rgba($white, 0.9);
$input-height: 2.8rem;
$input-padding-x: 1rem;
$table-accent-bg: rgba($primary-200, 0.5);
$custom-file-text: (
  en: 'Browse',
  ru: 'Выберите файл',
);
$custom-file-button-color: $primary-700;
$custom-file-button-bg: $primary-200;
$custom-file-height-inner: $input-height;
$custom-file-color: $primary-200;
$custom-file-border-color: $primary-100;
// Buttons
$btn-padding-y: 1rem;
$btn-padding-x: 1rem;
$btn-border-width: 2px;

// Tooltip
$tooltip-font-size: 0.8rem;
$tooltip-arrow-color: rgba($primary-900, 0.9);
$tooltip-bg: rgba($primary-900, 0.9);

// Tables
$table-cell-padding: 2px 0.75rem;

// Sidebar
$sidebar-width: 80px;

// Footer
$footer-height: 114px;

// Breakpoints
$xs: 0px;
$sm: 575px;
$md: 767px;
$lg: 991px; // Desktop
$xl: 1199px;
$xxl: 1959px;

// Font-sizes
$desktop-title: 36px;
$mobile-title: 24px;
$desktop-h1: 32px;
$desktop-h2: 26px;
$mobile-h1: 24px;
$mobile-h2: 18px;
$desktop-p: 18px;
$mobile-p: 14px;
$desktop-description: 14px;
$mobile-description: 11px;


