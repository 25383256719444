@import url('https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&display=swap');
@import 'assets/scss/variables';
@import 'assets/scss/scrollbar';
@import 'assets/scss/breadcrumb';
@import 'assets/scss/buttons';
@import 'assets/scss/card';
@import 'assets/scss/forms';
@import 'assets/scss/table';
@import 'assets/scss/modal';
@import '~bootstrap/scss/bootstrap';
@import 'assets/scss/typo';
body {
  margin: 0;
  font-family: $font-family-base !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*:hover,
*:active,
*:focus {
  outline: none !important;
}

img {
  max-width: 100%;
}
