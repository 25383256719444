@import 'variables';

[data-tooltip] {
  position: relative;
  &:hover::after {    
    content: attr(data-tooltip);    
    font-size: $tooltip-font-size;
    background-color: $tooltip-bg;
    color: $white;
    padding: 4px;
    border-radius: $border-radius-sm;
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
    transform: translateY(-100%);
    white-space: normal;    
  }
}

.clickable {
  cursor: pointer;
}
