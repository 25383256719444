.nav-breadcrumb {
  position: sticky;
  margin-bottom: 0;
  top: $header-height;
  min-height: 46px;
  display: flex;
  align-items: center;
  color: $white;
  font-size: 0.85em;
  z-index: 10;
  a {
    color: $white;
  }
  .breadcrumb {
    background: none !important;
    padding: 0;
  }
  ol {
    margin: 0;
  }
  &.nav-breadcrumb-unset {
    background-color: $primary-200;
  }
  &.nav-breadcrumb-dark {
    background-color: $primary-700;
  }
  &.nav-breadcrumb a:hover {
    color: $white;
    opacity: 0.8;
  }
}

@each $color, $value in $course-colors {
  .nav-breadcrumb-#{$color} {
    background-color: $value;
  }
}
