@import '/src/assets/scss/_variables.scss';


.sidebar-menu {
  height: 100%;
  padding-top: $header-height;
  ul {
    height: 100%;
  }
}

.menu-item {
  font-size: 10px;
  line-height: 12px;
  color: $primary-700;
  .ps-menu-label {
    position: relative;
    overflow: visible !important;
  };
  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: fit-content !important;
    padding: 5px 0 11px 0 !important;
    position: relative;
    .ps-menu-icon {
      height: 29px !important;
    }
    span {
      display: block;
      margin: 0;
      white-space: break-spaces;
      text-align: center;
      word-wrap: break-word;
      word-break: break-word;
    }
  }
}

.unchecked-badge {
  position: absolute;
  right: 13px;
  top: -16px;
  font-size: 8px;
}

@media (max-width: $sm) {
  .sidebar-menu {
    padding-top: 0px
  }
}
