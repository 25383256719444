input::placeholder {
  font-size: 18px;
  color: $primary-100;
}

input {
  min-height: 2.75rem;
  border-radius: $border-radius-lg;
  border: 1px solid rgba($primary-100, 0.5);
  padding: 0 $input-padding-x;

  &.invalid {
    background-color: rgba($danger, 0.5);
  }
}

.form-label {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}

input.form-control-lg {
  min-height: 80px;
  border-color: $primary;
}
.input-group.append-btn > .input-group-append > .input-group-text,
.input-group.append-btn > .input-group-append > .btn {
  border-radius: $border-radius-lg;
  margin-left: -8px;
  z-index: 9;
}

.img-skeleton {
  height: 190px;
  background-color: $gray-200;
  color: $primary-200;
  border-radius: $border-radius-lg;
  .img-skeleton-icon {
    background-color: $primary-200;
    color: $white;
    border-radius: 50%;
    padding: 1rem;
  }
}
// Form select

.form-select-label {
  font-size: 1.125rem;
  color: $primary-700;
}
.form-select {
  .dropdown-toggle {
    background-color: $primary-100;
    height: $input-height;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: $border-radius-lg;
    padding-left: $input-padding-x;
    padding-right: $input-padding-x;
    &:after {
      color: $primary;
      border: none !important;
      content: url("data:image/svg+xml,%3Csvg width='16' height='11' viewBox='0 0 16 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 2L8 8L14 2' stroke='%23389AF4' stroke-width='3'/%3E%3C/svg%3E%0A");
      cursor: pointer;
    }
    span {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .dropdown-menu {
    background-color: $primary-100;
    border-radius: $border-radius-lg;
    width: 100%;
    border: none;
    box-shadow: $box-shadow;
  }
  .dropdown-item {
    white-space: unset;
  }
  .dropdown-item:hover {
    background-color: $primary-200;
    font-weight: 600;
  }
}
$form-select-variant: (
  'white': $white,
  'primary-light': $primary-100,
);

@each $color in $form-select-variant {
  .form-select[variant='#{$color}'] {
    .dropdown-toggle,
    .dropdown-menu {
      background-color: $color;
    }
  }
}

// Checkbox and radiobutton

div.form-check {
  align-items: center;
  display: flex;
  .form-check-input {
    border-style: inset;
    position: relative;
    min-height: unset;
    width: 1.8rem;
    height: 1.8rem;
    margin-top: unset;
    + label {
      margin-left: 0.5rem;
    }
  }
}
.custom-input-group-check {
  .input-group-text {
    background: none;
    padding: 0;
    input {
      width: 2rem;
      height: 2rem;
    }
  }
}

.custom-disabled-red {
  input[disabled][checked] {
    filter: drop-shadow(0 0 0 red) contrast(200%);
  }
}
.custom-disabled-green {
  input[disabled][checked] {
    filter: drop-shadow(0 0 0 green) contrast(200%);
  }
}

.custom-input-group-check-student {
  .input-group-text {
    background: none;
    padding: 0;
    input {
      width: 21px;
      height: 21px;
    }
  }
}

.custom-file-input {
  border: 1px solid rgba($primary-100, 0.5);
  &::after {
    color: $primary-700;
    border-radius: $border-radius;
  }
}
.file-input-img {
  max-width: calc(100% - 40px);
}

textarea.adaptive {
  overflow: hidden;
  resize: none;
  min-height: 2.8rem !important;
}
