@import 'src/assets/scss/_variables.scss';

.pageTitle {
    color: $primary-700;
    font-size: 52px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 35px !important;
    margin-bottom: 50px !important;
}

.studentBreadcrumbs {
    color: $primary;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background-color: $gray-200;
    a {
            color: $primary !important;
            font-size: 16px !important;
            font-style: normal !important;;
            font-weight: 400 !important;;
            line-height: normal !important;;
    }
    svg {
        color: $primary !important;
        font-size: 16px !important;
        font-style: normal !important;;
        font-weight: 400 !important;;
        line-height: normal !important;;
    }
}
