:root {
  --scrollbarBg: rgba(238, 242, 247, 1);
  --scrollbarThumb: rgba(56, 154, 244, 0.8);
  --scrollbarWidth: 12px;
  --scrollbarBorder: 3px solid var(--scrollbarBg);
  --scrollbarBorderRadius: calc(var(--scrollbarWidth));
}

.scrollable {
  scrollbar-width: var(--scrollbarWidth);
  scrollbar-color: var(--scrollbarThumb) var(--scrollbarBg);
}
.scrollable::-webkit-scrollbar {
  width: var(--scrollbarWidth);
}
.scrollable::-webkit-scrollbar-track {
  background: var(--scrollbarBg);
  border-radius: var(--scrollbarBorderRadius);
}
.scrollable::-webkit-scrollbar-thumb {
  background-color: var(--scrollbarThumb);
  border-radius: var(--scrollbarBorderRadius);
  border: var(--scrollbarBorder);
}
