.btn {
  &:active,
  &:focus,
  &:hover {
    box-shadow: none !important;
  }

  &.btn-icon {
    width: 36px;
    height: 36px;
    border-radius: 18px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: $primary-200;
    color: $primary-700;
    box-sizing: border-box;
    overflow: visible;
    padding: unset;
    svg {
      height: 20px;
      width: 20px;
    }
    &:hover {
      background-color: rgba($primary, 0.7);
    }
    &[disabled] {
      opacity: 0.5;
    }
    &.btn-sm {
      transform: scale(0.75);
    }
  }
}

.btn.btn-outline-primary {
  color: unset;
}

.btn.btn-lg-select {
  height: 4rem;
  width: 6rem;
  border-radius: $border-radius-sm;
  border: 4px solid $primary;
  background-color: $white;
}

button {
  @extend .btn;
}
