@import '/src/assets/scss/_variables.scss';

.raw-sidebar {
  height: 100vh;
}

.sidebar-container {
  height: calc(100% - 65px);
}


