@import 'src/assets/scss/variables';

.footer {
  background-color: $primary-700;
  color: $white;
  font-size: $desktop-p;
  line-height: 33px;
  padding-top: 33px;
  padding-bottom: 15px;
  margin-top: 95px;

  .footerItemContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: space-between;
  }

  a {
    color: $white;
    text-decoration: none;
  }
  .footerContentContainer {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    padding: 0 10px;
  }
  .socialLinks {
    display: flex;
    gap: 15px;
  }
}

@media (max-width: $sm) {
  .footer {
    font-size: $mobile-description;
    line-height: 16px;
    font-weight: $p-font-weight;
    padding-top: 14px;
  }
}
