#notifications {
  position: fixed;
  z-index: 200;
  bottom: 0;
  right: 20px;
  display: flex;
  flex-direction: column-reverse;
  height: 100vh;
}
.toast {
  flex-basis: 100px;
  margin-bottom: 12px;
  &--success {
    background: #94cebe;
    color: white;
  }
  &--error {
    background: red;
    color: white;
  }
  &--warnung {
    background: yellow;
    color: white;
  }
}
