@import 'src/assets/scss/variables';

.overlayComp {
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: $primary-200;
  mix-blend-mode: multiply;
  isolation: isolate;
  flex-direction: column;
  overflow: auto;
  z-index: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}
