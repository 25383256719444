@import '/src/assets/scss/_variables.scss';

.AppRoot {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.AppRoot__Body {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.AppRoot__Content {
  height: 100%;
}

@media (min-width: $xl) {
  .AppRoot__Body {
    margin-left: $sidebar-width !important;
  }
}
